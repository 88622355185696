import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import USP from "../../components/usp"
import HeroSectionAlt from "../../components/heroSectionAlt"
import Testimonials from "../../components/testimonials"

function FaucetRepair() {
  const data = useStaticQuery(graphql`
    query northHoustonImages {
      northHoustonPlumber: file(
        relativePath: { eq: "north-houston-plumber.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="North Houston Plumbing Company"
        description={`Our company has been providing plumbing services for the North Houston community for over a decade. We offer all kinds of residential plumbing repairs. `}
      />
      <HeroSectionAlt
        h1={`North Houston Plumbing`}
        h2={`Your Local North Houston Plumber`}
        h3={`It is a privilege to be fulfilling the plumbing needs of the North Houston community on a daily basis. Founded in 2006 by master plumber Sid Bruton, Klein Plumbing Services has been exceeding the expectations of North Houston residents for 14 years. 
        `}
        p1={`We have a master plumber standing by to answer your questions totally risk-free. Call today and talk with one of our specialists for free.
        `}
        heroImg={data.northHoustonPlumber.childImageSharp.fluid}
        heroImgAltTag={`North Houston Plumbing Company`}
      />
      <section>
        <div className="pb-16 px-2 md:px-0">
          <h2 className="font-bold leading-8 text-2xl mb-4">
            About Klein Plumbing Services
          </h2>
          <div className="flex flex-col-reverse lg:flex-row">
            <div className="md:w-full lg:mr-8">
              <div className="md:flex">
                <p className="text-gray-600 leading-normal text-lg mb-4"></p>
              </div>
              <div className="">
                <p className="text-gray-600 text-lg mb-4">
                  Our goal since the company’s inception has been to treat
                  clients like family while providing excellent plumbing
                  services at an affordable price. This simple premise has
                  earned us a quality reputation with clients in North Houston.
                  Our family-owned and operated company only recommends the
                  repairs that are truly in your best interest, and our pricing
                  is transparent with no hidden fees.
                </p>
                <p className="text-gray-600 text-lg mb-4">
                  Our family-owned and operated company only recommends the
                  repairs that are truly in your best interest, and our pricing
                  is transparent with no hidden fees. All members of Klein
                  Plumbing Services are licensed and insured. Have peace of mind
                  in knowing that we’re qualified to handle your plumbing repair
                  and we’ll get it done right the first time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <USP />
      {/* <Testimonials mainTitle={`Our Customers`} /> */}
      <CTA
        title={`Get a Free Plumbing Quote Now`}
        buttonTitle={`Talk With a Master Plumber`}
      />
    </Layout>
  )
}

export default FaucetRepair
